import { LiveConst } from "../../constants/live";
import * as LiveActions from '../../redux/live/actions';
import { confirmAlert } from 'react-confirm-alert';
import CellaEventoCalcio from "./CelleEventoSport/CellaEventoCalcio";
import CellaEventoTennis from "./CelleEventoSport/CellaEventoTennis";
import CellaEventoBasket from "./CelleEventoSport/CellaEventoBasket";



const CellaEvento = (props) => {


    let heightEvento = props.style.height - 1;
    /**
     * Ritorno la descrizione dell'evento per un dato book recuperando le informazioni dall'elenco delle associazioni
     * @param {*} pevento_id 
     * @param {*} concessionario_id 
     */
    function getEventDescription(pevento_id, concessionario_id, elenco_associazioni){
        // TODO: rallentava l'esecuzione, da verificare
        return '';
        // ricerco all'interno dell'elenco delle associazioni l'associazione per l'evento e il concessionario
        // l'elenco delle associazioni è un dizionario dove ogni chiave contienne un dizionario con le informazioni dei singoli eventi
        // e dei concessionari
        let descrizione = 'Non disponibile';
        Object.keys(elenco_associazioni).every((key) => {
            if(elenco_associazioni[key]['pevento_id'] === pevento_id && elenco_associazioni[key]['concessionario_id'] === concessionario_id){
                descrizione = elenco_associazioni[key]['descrizione'];
            }else{
                return true;
            }
            
        })
        return descrizione;
    }
    
    function getCellaBySport(sport_id){
        switch(sport_id){
            case 1:
                return <CellaEventoCalcio evento={props.evento} style={{height: heightEvento + 'px'}} removeEvento={props.removeEvento} removeCampionato={props.removeCampionato}/>
            case 2:
                return <CellaEventoTennis evento={props.evento} style={{height: heightEvento + 'px'}} removeEvento={props.removeEvento} removeCampionato={props.removeCampionato}/>
            case 3:
                return <CellaEventoBasket evento={props.evento} style={{height: heightEvento + 'px'}} removeEvento={props.removeEvento} removeCampionato={props.removeCampionato}/>
            default:
                return <div className="col-4 overflow-hidden p-0"> NON DISPONIBILE</div>
        }
    }

    return(
        <>
        {props && props.evento &&
        <div key={props.chiave + props.evento.pevento_id} className="container overflow-hidden" style={{height: heightEvento + 'px', userSelect: "none"}}>
                <div className="row">
                    {getCellaBySport(props.psport_id)}
                    <div className="col-3 p-0">
                    {props.concessionari && props.concessionari.map((concessionario, index) => (
                        concessionario.visible &&
                            <div key={String(index) + String(props.evento.pevento_id)} title={getEventDescription(props.evento.pevento_id, concessionario.concessionario_id, props.elencoAssociazioniEventi)} className="col-12 text-nowrap p-1" style={{height: LiveConst.ALTEZZA_RIGA_CONCESSIONARIO, fontSize:'12px', borderBottom: '1px solid black'}}>{concessionario.descrizione}</div>
                        
                    ))}
                    </div>
                </div>
        </div>
        }</>
    );
}
export default CellaEvento;