import { LiveActionsType} from './constants'

type LiveAction = { type: string, payload: any };


export const setQuoteEvento = (pevento_id: number, quote: any): LiveAction =>{
    return{
      type: LiveActionsType.SET_QUOTE_EVENTO,
      payload: {
        pevento_id: pevento_id,
        quote: quote
      }
    }
}

export const addEvento = (evento: any, info_live: any, stato: any ): LiveAction =>{
    return{
      type: LiveActionsType.ADD_EVENTO,
      payload: {
        evento: evento,
        info_live: info_live,
        stato: stato
      }
    }
}

export const getElencoAssociazioniEventi = (elencoQuote): LiveAction =>{
    return{
      type: LiveActionsType.GET_ASSOCIAZIONI_EVENTI,
      payload: elencoQuote
    }
}

export const getQuoteEvento = (pevento_id: number): LiveAction =>{
    return{
      type: LiveActionsType.GET_QUOTE_EVENTO,
      payload: {
        pevento_id: pevento_id
      }
    }
}

export const getEvento = (pevento_id: number, info_live: any, stato: any): LiveAction =>{
    return{
      type: LiveActionsType.GET_EVENTO,
      payload: {
        pevento_id: pevento_id,
        info_live: info_live,
        stato: stato
      } 
    }
}


export const setEventoDati = (pevento_id: number, info_live: any, stato: any): LiveAction =>{
    return{
      type: LiveActionsType.SET_EVENTO_DATI,
      payload: {
        pevento_id: pevento_id,
        info_live: info_live,
        stato: stato
      }
    }
}


export const deleteEvento = (pevento_id: number): LiveAction =>{
    return{
      type: LiveActionsType.DELETE_EVENTO,
      payload: pevento_id
    }
}

export const setElencoAssociazioniEventi = (elencoAssociazioniEventi: any, elencoQuote: any): LiveAction =>{
    return{
      type: LiveActionsType.SET_ASSOCIAZIONI_EVENTI,
      payload: {
        elencoAssociazioniEventi: elencoAssociazioniEventi,
        elencoQuote: elencoQuote
      }
    }
}

export const setQuoteFromSocket = (elencoQuote: any): LiveAction => {
    return {
      type: LiveActionsType.SET_QUOTE_FROM_SOCKET,
      payload: elencoQuote
    }
}

export const getEventi = (eventiDict): LiveAction =>{
    return{
      type: LiveActionsType.GET_EVENTI,
      payload: {
        eventiDict: eventiDict
      }
    }
}

export const getEventiCompleto = (eventiDict): LiveAction =>{
  return{
    type: LiveActionsType.GET_EVENTI_COMPLETO,
    payload: {
      eventiDict: eventiDict
    }
  }
}

export const addEventi = (eventi: any, info_eventi): LiveAction => {
    return {
      type: LiveActionsType.ADD_EVENTI,
      payload: {
        eventi: eventi,
        info_eventi: info_eventi
      }
    }
}

export const addEventiConQuote = (eventi: any, quote: any, info_eventi): LiveAction => {
  return {
    type: LiveActionsType.ADD_EVENTI_CON_QUOTE,
    payload: {
      eventi: eventi,
      quote: quote,
      info_eventi: info_eventi
    }
  }
}

export const getSpecialita = (): LiveAction =>{
    return{
      type: LiveActionsType.GET_SPECIALITA,
      payload: false
    }
}

export const addSpecialita = (specialita: any): LiveAction => {
    return {
      type: LiveActionsType.ADD_SPECIALITA,
      payload: specialita
    }
}

export const getConcessionari = (): LiveAction =>{
    return{
      type: LiveActionsType.GET_CONCESSIONARI,
      payload: false
    }
}

export const addConcessionari = (concessionari: any): LiveAction => {
    return {
      type: LiveActionsType.ADD_CONCESSIONARI,
      payload: concessionari
    }
}


export const setConcessionarioVisible = (concessionario_id: number, visible: boolean): LiveAction =>{
    return{
      type: LiveActionsType.SET_CONCESSIONARIO_VISIBLE,
      payload: {
        concessionario_id: concessionario_id,
        visible: visible
      }
    }
}

export const setDistanceConfronto = (concessionario_id: number, distanza: number): LiveAction =>{
    return{
      type: LiveActionsType.SET_DISTANCE_CONFRONTO,
      payload: {
        concessionario_id: concessionario_id,
        distanza: distanza
      }
    }
}

export const changeAbilitazioneCofronto = (concessionario_id: number, abilitato: boolean): LiveAction =>{
    return{
      type: LiveActionsType.CHANGE_ABILITAZIONE_CONFRONTO,
      payload: {
        concessionario_id: concessionario_id,
        abilitato: abilitato
      }
    }
}

export const checkAlerts = (): LiveAction =>{
    return{
      type: LiveActionsType.CHECK_ALERTS,
      payload: false
    }
}


export const removeEvento = (pevento_id: number): LiveAction =>{
    return{
      type: LiveActionsType.REMOVE_EVENTO,
      payload: pevento_id
    }
}

export const setEyeState = (eyeState: boolean): LiveAction =>{
    return{
      type: LiveActionsType.SET_EYE_STATE,
      payload: eyeState
    }
}

export const checkFilters = (): LiveAction =>{
    return{
      type: LiveActionsType.CHECK_FILTERS,
      payload: false
    }
}

export const setSpecialitaVisible = (specialita_id: number, visible: boolean): LiveAction =>{
    return{
      type: LiveActionsType.SET_SPECIALITA_VISIBLE,
      payload: {
        id: specialita_id,
        visible: visible
      }
    }
}

export const setFiltroEvento = (filtro: string): LiveAction =>{
    return{
      type: LiveActionsType.SET_FILTRO_EVENTO,
      payload: filtro
    }
}

export const setCampionatoNonVisibile = (pcampionato_id: number): LiveAction =>{
    return{
      type: LiveActionsType.SET_CAMPIONATO_NON_VISIBILE,
      payload: pcampionato_id
    }
}

export const setColoreConcessionario = (concessionario_id: number, color: string): LiveAction =>{
    return{
      type: LiveActionsType.SET_COLORE_CONCESSIONARIO,
      payload: {
        concessionario_id: concessionario_id,
        color: color
      }
    }
}


export const salvaStato = (): LiveAction =>{
    return{
      type: LiveActionsType.SALVA_STATO,
      payload: false
    }
} 

export const salvaStatoCampionatiIgnorati = () : LiveAction => {
  return{
    type: LiveActionsType.SALVA_STATO_CAMPIONATI_DEFAULT,
    payload: false
  }
}

export const setAlertSound = (alertSound: boolean): LiveAction =>{
    return{
      type: LiveActionsType.SET_ALERT_SOUND,
      payload: alertSound
    }
}

export const setParameters = (parameters: any): LiveAction =>{
    return{
      type: LiveActionsType.SET_PARAMETERS,
      payload: parameters
    }
}

export const getInversioniQuote = (): LiveAction =>{
    return{
      type: LiveActionsType.GET_INVERSIONI_QUOTE,
      payload: false
    }
}

export const setInversioniQuote = (quoteInversione: any): LiveAction =>{
    return{
      type: LiveActionsType.SET_INVERSIONI_QUOTE,
      payload: quoteInversione
    }
}


// Azione richiamata sul componente alert tramite cestino per rimuovere un alert dalla barra
export const removeAlertBar = (alertId : any): LiveAction =>
{
  return{
    type: LiveActionsType.REMOVE_ALERT_BAR,
    payload: {
      id: alertId,
    }}
}

// Azione richiamata per configurare le informazioni globali riguardante il sorting dell'alert
export const sortAlertBarInfo = (sortingType : any, sortingOrder: any) : LiveAction =>
  {
    return{
      type: LiveActionsType.SORT_ALERT_BAR_INFO,
      payload: {
        sorting: sortingType,
        order: sortingOrder
      }
    }
  }

// Azione richiamata per eseguire il sorting dell'alert 
export const sortAlertBar = (): LiveAction =>
{
  return {
    type: LiveActionsType.SORT_ALERT_BAR,
    payload: false
  }
}

// Azione richiamata per settare la visibilità di più campionati
export const setCampionatiVisibilita = (campionati_id: any,visibile: any): LiveAction =>
{
  return{
    type: LiveActionsType.SET_CAMPIONATI_VISIBILITA,
    payload: {
      campionati: campionati_id,
      visibilita: visibile
    }
  }
}

// Azione richiamata per aggiungere un campionato ignorato alle impostazioni di default
export const AddCampionatoIgnoratoDefault = (campionato_id: any, campionato_descrizione: any): LiveAction =>
  {
    return{
      type: LiveActionsType.ADD_CAMPIONATO_INGNORATO_DEFAULT,
      payload: {
        id: campionato_id,
        descrizione: campionato_descrizione
      }
    }
  }
  
  // Azione richiamata per rimuovere un campionato ignorato dalle impostazioni di default
  export const RemoveCampionatoIgnoratoDefault = (campionato_id: any): LiveAction =>
    {
      return{
        type: LiveActionsType.REMOVE_CAMPIONATO_IGNORATO_DEFAULT,
        payload: {
          id: campionato_id,
        }
      }
    }
