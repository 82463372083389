import { put, takeEvery, all, fork, select } from 'redux-saga/effects'
import { LiveActionsType } from './constants';
import * as LiveAction from './actions';
import { serverConst } from '../../constants/live'


const token_state = state => state.Live.token;
const psport_id_state = state => state.Live.psport_id;


function* fetchEvento(params): any {
    const token = yield select(token_state);
    const psport_id = yield select(psport_id_state);
    const requestOption = {
      method: 'POST',
      body: JSON.stringify({'token': token, 'pevento_id': params.payload.pevento_id, 'psport_id': psport_id}),
      headers: {
          'Accept': 'application/json',
      },
    }
    const json_respone = yield fetch(serverConst.END_POINT_LIVE + serverConst.END_POINT_GET_EVENTO, requestOption).then(response => response.json(), );
    yield put(LiveAction.addEvento(json_respone, params.payload.info_live, params.payload.stato));
}

function* fetchQuote(params): any{
    const token = yield select(token_state);
    const psport_id = yield select(psport_id_state);
    var errore_quote = false;
    const requestOption = {
        method: 'POST',
        body: JSON.stringify({'token':token, 'pevento_id': params.payload.pevento_id, 'psport_id': psport_id}),
        headers: {
            'Accept': 'application/json',
        },
    }
    const json_respone = yield fetch(serverConst.END_POINT_LIVE + serverConst.END_POINT_GET_QUOTE_FOR_EVENTO, requestOption)
        .then(response => {
          if(response.status !== 200){
            errore_quote = true
            return {}
          }
          return response.json()
        })
        .catch(error => {
          errore_quote = true
          return {}
      });
    yield put(LiveAction.setQuoteEvento(params.payload.pevento_id, json_respone))
}

function* fetchElencoAssociazioniEventi(params) {
    const token = yield select(token_state);
    const psport_id = yield select(psport_id_state);
    const requestOption = {
      method: 'POST',
      body: JSON.stringify({'token': token, 'psport_id': psport_id}),
      headers: {
          'Accept': 'application/json',
      },
    }
    let elencoQuote = params.payload;
    const json_respone = yield fetch(serverConst.END_POINT_LIVE + serverConst.END_POINT_ELENCO_ASSOCIAZIONI,requestOption).then(response => response.json(), );
    yield put(LiveAction.setElencoAssociazioniEventi(json_respone, elencoQuote));
}

function* fetchEventi(params): any {
    const token = yield select(token_state);
    const psport_id = yield select(psport_id_state);
    var eventi = params.payload.eventiDict;
    var list_peventi_id = [];
    var dict_peventi_id_info_live = {};
    eventi.forEach(element => {
        list_peventi_id.push(element.pevento_id);
        dict_peventi_id_info_live[element.pevento_id] = {};
        dict_peventi_id_info_live[element.pevento_id].info_live = element.info_live;
        dict_peventi_id_info_live[element.pevento_id].stato = element.stato;
    });

    const requestOption = {
      method: 'POST',
      body: JSON.stringify({'token': token, 'peventi_id': list_peventi_id, 'psport_id': psport_id}),
      headers: {
          'Accept': 'application/json',
      },
    }
    const json_respone = yield fetch(serverConst.END_POINT_LIVE + serverConst.END_POINT_GET_EVENTI,requestOption).then(response => response.json(), );
    yield put(LiveAction.addEventi(json_respone, dict_peventi_id_info_live));
}

function* fetchEventiCompleto(params): any {
    const token = yield select(token_state);
    const psport_id = yield select(psport_id_state);
    var eventi = params.payload.eventiDict;
    var list_peventi_id = [];
    var dict_peventi_id_info_live = {};
    eventi.forEach(element => {
        list_peventi_id.push(element.pevento_id);
        dict_peventi_id_info_live[element.pevento_id] = {};
        dict_peventi_id_info_live[element.pevento_id].info_live = element.info_live;
        dict_peventi_id_info_live[element.pevento_id].stato = element.stato;
    });

    const requestOption = {
      method: 'POST',
      body: JSON.stringify({'token': token, 'peventi_id': list_peventi_id, 'psport_id': psport_id}),
      headers: {
          'Accept': 'application/json',
      },
    }
    const json_respone = yield fetch(serverConst.END_POINT_LIVE + serverConst.END_POINT_GET_EVENTI_COMPLETO,requestOption).then(response => response.json(), );
    yield put(LiveAction.addEventiConQuote(json_respone['eventi'], json_respone['quote'], dict_peventi_id_info_live));
}


function* fetchSpecialita() {
    const token = yield select(token_state);
    const psport_id = yield select(psport_id_state);
    const requestOption = {
      method: 'POST',
      body: JSON.stringify({'token': token, 'psport_id': psport_id}),
      headers: {
          'Accept': 'application/json',
      },
    }
    const json_respone = yield fetch(serverConst.END_POINT_LIVE + serverConst.END_POINT_GET_SPECIALITA,requestOption).then(response => response.json(), );
    yield put(LiveAction.addSpecialita(json_respone));
}

function* fetchConcessionari(): any {
    const token = yield select(token_state);
    const requestOption = {
      method: 'POST',
      body: JSON.stringify({'token': token}),
      headers: {
          'Accept': 'application/json',
      },
    }  
    const json_respone = yield fetch(serverConst.END_POINT_LIVE + serverConst.END_POINT_GET_CONCESSIONARI, requestOption).then(response => response.json(), );
    yield put(LiveAction.addConcessionari(json_respone));
}


function* fetchInversioniQuote(): any {
    const token = yield select(token_state);
    const requestOption = {
        method: 'POST',
        body: JSON.stringify({'token': token}),
        headers: {
            'Accept': 'application/json',
        },
    }
    const json_respone = yield fetch(serverConst.END_POINT_LIVE + serverConst.END_POINT_GET_INVERSIONI_QUOTE, requestOption).then(response => response.json(), );
    yield put(LiveAction.setInversioniQuote(json_respone));
}

function* concessionariWatcher(): any {
    yield takeEvery(LiveActionsType.GET_CONCESSIONARI, fetchConcessionari)
}

function* specialitaWatcher() {
    yield takeEvery(LiveActionsType.GET_SPECIALITA, fetchSpecialita)
}
  
function* elencoAssociazioniEventiWatcher() {
    yield takeEvery(LiveActionsType.GET_ASSOCIAZIONI_EVENTI, fetchElencoAssociazioniEventi)
}

function* quoteWatchers(): any{
    yield takeEvery(LiveActionsType.GET_QUOTE_EVENTO, fetchQuote);
    //yield takeEvery(IppicaActionsType.SET_QUOTA, fetchQuotaDescrizione);
}
  
function* eventiWatcher(): any {
    yield takeEvery(LiveActionsType.GET_EVENTO, fetchEvento)
    yield takeEvery(LiveActionsType.GET_EVENTI, fetchEventi)
    yield takeEvery(LiveActionsType.GET_EVENTI_COMPLETO, fetchEventiCompleto)
}

function* quoteInversioneWatcher(): any {
    yield takeEvery(LiveActionsType.GET_INVERSIONI_QUOTE, fetchInversioniQuote)
}

function* LiveSaga(): any {
    yield all([
        fork(eventiWatcher),
        fork(quoteWatchers),
        fork(elencoAssociazioniEventiWatcher),
        fork(specialitaWatcher),
        fork(concessionariWatcher),
        fork(quoteInversioneWatcher)
    ]);
}

  
export default LiveSaga;