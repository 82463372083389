export const LiveActionsType = {
    GET_SPECIALITA: '@@live/GET_SPECIALITA',
    GET_CONCESSIONARI: '@@live/GET_CONCESSIONARI',
    GET_EVENTI: '@@live/GET_EVENTI',
    GET_EVENTI_COMPLETO: '@@live/GET_EVENTI_COMPLETO',
    GET_EVENTO: '@@live/GET_EVENTO',
    GET_QUOTE_EVENTO: '@@live/GET_QUOTE_EVENTO',
    GET_ASSOCIAZIONI_EVENTI: '@@live/GET_ASSOCIAZIONI_EVENTI',
    GET_INVERSIONI_QUOTE: '@@live/GET_INVERSIONI_QUOTE',
    SET_INVERSIONI_QUOTE: '@@live/SET_INVERSIONI_QUOTE',
    ADD_EVENTI: '@@live/ADD_EVENTI',
    ADD_EVENTI_CON_QUOTE: '@@live/ADD_EVENTI_CON_QUOTE',
    ADD_CONCESSIONARI: '@@live/ADD_CONCESSIONARI',
    ADD_SPECIALITA: '@@live/ADD_SPECIALITA',
    SET_QUOTE_EVENTO: '@@live/SET_QUOTE_EVENTO',
    SET_ASSOCIAZIONI_EVENTI: '@@live/SET_ASSOCIAZIONI_EVENTI',
    SET_EVENTO_DATI: '@@live/SET_EVENTO_DATILIVE',
    SET_QUOTE_FROM_SOCKET: '@@live/SET_QUOTE_FROM_SOCKET',
    ADD_EVENTO: '@@live/ADD_EVENTO',
    DELETE_EVENTO: '@@live/DELETE_EVENTO',
    SET_CONCESSIONARIO_VISIBLE: '@@live/SET_CONCESSIONARIO_VISIBLE',
    SET_DISTANCE_CONFRONTO: '@@live/SET_DISTANCE_CONFRONTO',
    CHANGE_ABILITAZIONE_CONFRONTO: '@@live/CHANGE_ABILITAZIONE_CONFRONTO',
    CHECK_ALERTS: '@@live/CHECK_ALERTS',
    REMOVE_EVENTO: '@@live/REMOVE_EVENTO',
    SET_EYE_STATE: '@@live/SET_EYE_STATE',
    CHECK_FILTERS: '@@live/CHECK_FILTERS',
    SET_SPECIALITA_VISIBLE: '@@live/SET_SPECIALITA_VISIBLE',
    SET_FILTRO_EVENTO: '@@live/SET_FILTRO_EVENTO',
    SET_CAMPIONATO_NON_VISIBILE: '@@live/SET_CAMPIONATO_NON_VISIBILE',
    SET_COLORE_CONCESSIONARIO: '@@live/SET_COLORE_CONCESSIONARIO',
    SALVA_STATO: '@@live/SALVA_STATO',
    SET_ALERT_SOUND: '@@live/SET_ALERT_SOUND',
    SET_PARAMETERS: '@@live/SET_PARAMETERS',

    ADD_ALERT_BAR: '@@live/ADD_ALERT_BAR', // azione usata per aggiungere gli alert allo stato generale
    REMOVE_ALERT_BAR: '@@live/REMOVE_ALERT_BAR', // azione usata per rimuovere gli alert dallo stato generale
    SORT_ALERT_BAR_INFO: '@@live/SORT_ALERT_BAR_INFO', // azione usata per settare le info riguardanti il sort degli alert
    SORT_ALERT_BAR: '@@live/SORT_ALERT_BAR', // azione usata per modificare l'ordine degli alert in base al sort configurato
    SET_CAMPIONATI_VISIBILITA: '@@live/SET_CAMPIONATI_VISIBILITA', // modifica la visibilità dei campionato
    ADD_CAMPIONATO_INGNORATO_DEFAULT: '@@live/ADD_CAMPIONATO_IGNORATO_DEFAULT', // Aggiunge un campionato ignorato alle impostazioni salvate
    REMOVE_CAMPIONATO_IGNORATO_DEFAULT: '@@live/REMOVE_CAMPIONATO_IGNORATO_DEFAULT', // Rimuove un campionato ignorato dalle impostazioni salvate
    SALVA_STATO_CAMPIONATI_DEFAULT: '@@live/SALVA_STATO_CAMPIONATI_DEFAULT' // Salva lo stato attuale dei campionati ignorati di default
};

// Enum per identificare le informazioni inerenti al sorting degli alert
export const alertBarInfoEnum = Object.freeze({
    PERCENTUALE_GUADAGNO: 0,
    TIMESTAMP: 1,
    
    CRESCENTE: 2,
    DECRESCENTE: 3
  });